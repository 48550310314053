.gradient-transition {
  height: 50px;
  background: #343D24;
  width: 100vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 56px;
}

.landing-splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 56px);
  text-align: center;
  color: #F9F6F0;
  background-image: url('../../assets/images/background.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  margin: 0;
  position: relative; /* To position the countdown at the bottom */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
}

.subtitle {
  font-size: 1.2rem; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paradise {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: 7.5vw;
  max-width: 100%;
  color: #F9F6F0;
  perspective: 1000px; /* Define the perspective for the 3D effect */
}

.paradise-text {
  display: inline-block;
  transform-style: preserve-3d; /* Preserve the 3D effect */
}

.countdown-widget {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  text-align: center;
  font-size: 1.5rem; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.7); /* Adjust background color and opacity as needed */
  color: #F9F6F0;
  padding: 10px;
  border-radius: 10px;
  max-width: 90%; /* Adjust as needed */
  margin: 0 auto; /* Center horizontally */
}

@media only screen and (max-width: 767px) {
  .bccss {
    font-size: 12px;
  }

  .subtitle {
    font-size: 1rem;
  }

  .paradise {
    font-size: 14vw;
  }

  .countdown-widget {
    font-size: 1rem; /* Adjust for smaller screens */
    bottom: 10px; /* Adjust for smaller screens */
    padding: 5px;
    max-width: 95%; /* Adjust as needed */
  }

  .landing-splash-container {
    width: 100vw;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .landing-splash-container {
    width: 100vw;
    margin: 0;
  }
}
