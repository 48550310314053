/* Import the required fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&family=Poppins:wght@400&display=swap');

/* Root variables for colors */
:root {
  --font-color: #F9F6F0;
  --secondary-color: #343D24;
  --background-color: #647D5B;
  --secondary-background-color: #D6CEA2;
  --accent-color: #BD897E;
}

/* General styles */
body {
  background-color: var(--background-color);
  color: var(--font-color);
  font-family: 'Poppins', sans-serif;
}

.container {
  background-color: var(--secondary-background-color);
  padding: 2rem;
}

.about-heading {
  color: var(--secondary-color);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  background-color: var(--secondary-background-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.about-description {
  color: var(--background-color);
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 3rem;
}

.icon-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.icon-card {
  background-color: var(--background-color);
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  flex: 1;
}

.icon-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon-card svg {
  color: var(--secondary-background-color);
  margin-bottom: 1rem;
}

.icon-text {
  color: var(--font-color);
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.card-description {
  color: var(--secondary-background-color);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .icon-container {
    flex-direction: column;
  }
  
  .icon-card {
    width: 100%;
  }
}