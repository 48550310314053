/* Import the required fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&family=Poppins:wght@400&display=swap');

:root {
  --font-color: #F9F6F0;
  --secondary-color: #343D24;
  --background-color: #647D5B;
  --secondary-background-color: #D6CEA2;
  --accent-color: #BD897E;
}

.container {
  padding: 2rem;
}

.events-heading {
  color: var(--secondary-color);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.events-description {
  color: var(--background-color);
  text-align: center;
  margin-bottom: 2rem;
}

.card {
  background-color: var(--background-color);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.card-icon {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon svg {
  color: var(--secondary-background-color);
}

.card-details h3 {
  color: var(--font-color);
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-details p {
  color: var(--secondary-background-color);
  margin: 0;
}

.card-content p {
  color: var(--font-color);
}

.timeline-item {
  margin-bottom: 2rem;
}

/* You can add this if you want to use the accent color somewhere */
.accent-text {
  color: var(--accent-color);
}

.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline {
  width: 95%
}