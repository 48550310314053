@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&family=Poppins:wght@300;400;500;600;900&display=swap');

:root {
    --font-color: #F9F6F0;
    --secondary-color: #343D24;
    --background-color: #647D5B;
    --secondary-background-color: #D6CEA2;
    --accent-color: #BD897E;
  }

.container {
    background-color: var(--secondary-background-color);
    padding: 2rem;
  }
  
  .faq-heading {
    color: var(--secondary-color);
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faq-item {
    background-color: var(--background-color);
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question h3 {
    color: var(--font-color);
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
  }
  
  .faq-question svg {
    color: var(--secondary-background-color);
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-answer.active {
    max-height: 1000px;
    padding-top: 1rem;
  }
  
  .faq-answer p {
    color: var(--secondary-background-color);
    margin: 0;
  }