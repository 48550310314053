@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Root variables for colors */
:root {
    --font-color: #F9F6F0;
    --secondary-color: #343D24;
    --background-color: #647D5B;
    --secondary-background-color: #D6CEA2;
    --accent-color: #BD897E;
}
  
  .sponsors-container {
    background-color: var(--secondary-background-color);
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sponsors-heading {
    color: var(--secondary-color);
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }
  
  .sponsors-grid {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 800px;
  }
  
  .sponsor-item {
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sponsor-logo {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .sponsor-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .sponsor-name {
    color: var(--font-color);
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }
  
  .sponsors-contact {
    color: var(--background-color);
    text-align: center;
    font-size: 1.1rem;
    margin-top: 2rem;
    max-width: 600px;
  }
  
  .sponsors-contact a {
    color: var(--secondary-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .sponsors-contact a:hover {
    color: var(--accent-color);
  }
  
  @media (min-width: 768px) {
    .sponsors-grid {
      flex-direction: row;
      justify-content: center;
    }
  
    .sponsor-item {
      flex: 1;
      max-width: 400px;
    }
  }