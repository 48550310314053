.carousel-container {
  background-color: var(--background-color);
  padding: 3rem 1rem;
  margin-bottom: 2rem;
  border-radius: 12px;
}

.carousel-heading {
  color: var(--font-color);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.carousel {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.slide-container {
  position: relative;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slide {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  display: block;
  opacity: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--font-color);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 4;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.arrow-left {
  left: -20px;
}

.arrow-right {
  right: -20px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.indicator {
  background-color: var(--secondary-background-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: var(--accent-color);
}

@media (max-width: 768px) {
  .carousel-heading {
    font-size: 2rem;
  }

  .arrow {
    width: 30px;
    height: 30px;
  }

  .arrow-left {
    left: 10px;
  }

  .arrow-right {
    right: 10px;
  }
}